import React from 'react';

export const WavyCircleIcon = () => (
  <svg
    data-test="wavy-circle-icon"
    width="44"
    height="44"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-auto"
  >
    <circle cx="20" cy="20" r="20" />
    <path
      d="M34.338 17.626L34.7556 17.351L34.338 17.626L34.6337 18.0751C35.4029 19.2431 35.4029 20.7569 34.6337 21.9249L34.338 22.374C33.8046 23.1841 33.5471 24.1448 33.6041 25.1131L33.6356 25.6498C33.7177 27.0459 32.9608 28.3569 31.7107 28.9839L31.2301 29.2249C30.3631 29.6597 29.6597 30.3631 29.2249 31.2301L28.9839 31.7107C28.3569 32.9608 27.0459 33.7177 25.6498 33.6356L25.1131 33.6041C24.1448 33.5471 23.1841 33.8046 22.374 34.338L21.9249 34.6337C20.7569 35.4029 19.2431 35.4029 18.0751 34.6337L17.626 34.338L17.351 34.7556L17.626 34.338C16.8159 33.8046 15.8552 33.5471 14.8869 33.6041L14.3502 33.6356C12.9541 33.7177 11.6431 32.9608 11.0161 31.7107L10.7751 31.2301C10.3403 30.3631 9.63693 29.6597 8.7699 29.2249L8.2893 28.9839C7.03916 28.3569 6.28226 27.0459 6.36436 25.6498L6.39593 25.1131C6.45288 24.1448 6.19544 23.1841 5.66199 22.374L5.36629 21.9249C4.59711 20.7569 4.59711 19.2431 5.36629 18.0751L5.66199 17.626C6.19544 16.8159 6.45288 15.8552 6.39593 14.8869L6.36436 14.3502C6.28226 12.9541 7.03916 11.6431 8.2893 11.0161L8.7699 10.7751C9.63693 10.3403 10.3403 9.63693 10.7751 8.7699L11.0161 8.2893C11.6431 7.03916 12.9541 6.28226 14.3502 6.36436L14.8869 6.39593C15.8552 6.45288 16.8159 6.19544 17.626 5.66199L18.0751 5.36629C19.2431 4.59711 20.7569 4.59711 21.9249 5.36629L22.374 5.66199C23.1841 6.19544 24.1448 6.45288 25.1131 6.39593L25.6498 6.36436C27.0459 6.28226 28.3569 7.03916 28.9839 8.2893L29.2249 8.7699C29.6597 9.63693 30.3631 10.3403 31.2301 10.7751L31.7107 11.0161C32.9608 11.6431 33.7177 12.9541 33.6356 14.3502L33.6041 14.8869C33.5471 15.8552 33.8046 16.8159 34.338 17.626Z"
      stroke="#5A5E5A"
      strokeLinejoin="round"
    />
  </svg>
);
